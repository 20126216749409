import React, { useEffect, useState } from "react";
import { Input, Form, message, Divider } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";
import logo3 from "../../assets/classileads.png";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const Login = (AuthReducer) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [agentId, setAgentId] = useState("");
  const { loading } = AuthReducer;
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    const agent = queryParams?.get("agent");
    setAgentId(agent);
  }, []);

  const handleSubmitLogin = (values) => {
    let data1 = {
      email: "",
      password: "",
    };
    let data2 = {
      email: "",
      password: "",
      agent_id: "",
      app_name: "",
    };

    if (agentId) {
      data2 = {
        email: values?.email,
        password: values?.password,
        agent_id: Number(agentId),
        app_name: agentId && "CLASSIESTATE",
      };
    } else {
      data1 = {
        email: values?.email,
        password: values?.password,
        module_id: 6,
      };
    }
    dispatch(
      allActions(agentId ? { ...data2 } : { ...data1 }, {
        method: "post",
        endPoint: "authenticate",
        attempt: "LOGIN_REQUEST_ATTEMPT",
        success: "LOGIN_REQUEST_SUCCESS",
        failure: "LOGIN_REQUEST_FAILURE",

        saveBearerToken: true,
        successInternalState: (res) => {
          localStorage.setItem("agEmail", res?.profile?.user_email);
          localStorage.setItem("userId", res?.profile?.userid);

          fetchUserProfile();
          navigate("/dashboard");
          // res?.profile?.is_agency
          //   ? navigate("/dashboard")
          //   : message.error("Your account is  not authorized, Please verify !");
        },
        failureInternalState: () => {},
      })
    );
  };

  const fetchUserProfile = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            // localStorage.setItem("is_agent", res?.is_agent);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const googlelogin = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        })
        .then((res) => {
          dispatch(
            allActions(
              {
                first_name: res?.data?.given_name,
                last_name: res?.data?.family_name,
                token: res?.data?.id,
                email: res?.data?.email,
                is_apple: false,
                referal_code: "",
              },
              {
                method: "post",
                endPoint: "social_authenticate",
                attempt: "GOOGLE_LOGIN_REQUEST_ATTEMPT",
                success: "GOOGLE_LOGIN_REQUEST_SUCCESS",
                failure: "GOOGLE_LOGIN_REQUEST_FAILURE",

                saveBearerToken: true,
                successInternalState: (res) => {
                  // res?.profile?.is_agency
                  //   ? navigate("/dashboard")
                  //   : message.error(
                  //       "Your account is  not authorized, Please verify !"
                  //     );
                  navigate("/dashboard");
                },
                failureInternalState: () => {},
              }
            )
          );
        })
        .catch((error) => message.error("Login Failed"));
    },
  });

  return (
    <div className="login--container">
      <div className="login--container--body">
        {loading && <LoaderPrimary />}

        <Form
          layout="vertical"
          className="form--container"
          onFinish={handleSubmitLogin}
        >
          <img
            src={logo3}
            style={{
              width: "17rem",
              height: "auto",
            }}
            alt="classiEstate Logo"
          />
          <p
            className="login--header"
            style={{ fontWeight: "semibold", marginBottom: "1rem" }}
          >
            Login to your account!
          </p>

          <Form.Item
            className="login--input"
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Email is required</span>
                ),
              },
            ]}
          >
            <Input
              className="login--input login--email"
              size="large"
              type="email"
              placeholder="Email"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            className="login--input"
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    Password is required
                  </span>
                ),
              },
            ]}
          >
            <Input.Password
              className="login--input  login--password"
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </Form.Item>
          <button
            disabled={loading}
            type="submit"
            style={{
              outline: "0",
              border: "0",
              margin: 0,
              borderRadius: "1rem !important",
              margin: "0",
              width: "100%",
              fontSize: "1rem",
            }}
            className="login--button"
          >
            SIGN IN
          </button>
        </Form>
        <NavLink
          style={{ textDecoration: "none", fontSize: "14px" }}
          to="/auth/forget-password"
          className="login--text"
        >
          Forgot Password?
        </NavLink>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              margin: "1rem 0",
            }}
          >
            <span
              style={{
                position: "absolute",
                padding: "0 0.5rem",
                background: "white",
                color: "gray",
                fontSize: "1.2rem",
                zIndex: 1000,
              }}
            >
              OR
            </span>
            <Divider
              style={{
                position: "absolute",
                borderBottom: "1px solid #80808070",
              }}
            />
          </div>
          <p style={{ fontSize: "14px", textAlign: "center" }}>
            Don’t have an account with Classileads?
            <Link to={"/auth/signup"}> Sign Up</Link>
          </p>
          <button
            style={{
              width: "100%",
              border: "none",
              borderRadius: "1.5rem",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "1rem",
              padding: "0.5rem 2rem",
              alignItems: "center",
              fontWeight: "semi-bold",
              fontSize: "1rem",
            }}
            onClick={() => googlelogin()}
          >
            <FcGoogle style={{ fontSize: "1.5rem" }} /> Continue with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Login);
