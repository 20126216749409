import React from "react";
import ReviewElement from "../../component/reviewElement/ReviewElement";

export default function ReviewedComponent() {
  return (
    <div
      style={{
        background: "white",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        padding: "1rem",
      }}
    >
      <ReviewElement />
      <ReviewElement />
      <ReviewElement />
    </div>
  );
}
