import { UserOutlined } from "@ant-design/icons";
import { Avatar, Rate } from "antd";
import moment from "moment";
import React from "react";

export default function ReviewElement({ review }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        border: "1px solid #cfcfcf90",
        borderRadius: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Avatar
          size={64}
          style={{
            display: "flex",
            flex: "row",
            alignItems: "center",
          }}
          icon={<UserOutlined style={{ margin: "auto" }} />}
        />
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
          <h3>
            {review?.first_name} {review?.last_name}
          </h3>
          <span>
            {review?.gmt
              ? moment(review?.gmt).local().format("DD MMMM YYYY hh-mm a")
              : null}
          </span>
        </div>
        <Rate allowHalf defaultValue={review?.rate} />
      </div>
      <p style={{ color: "gray" }}>{review?.message}</p>
    </div>
  );
}
