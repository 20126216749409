import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Select, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../../../Redux/myActions";

export default function CompanyReferences({
  formData,
  setFormData,
  setCurrent,
  fetchProfileUpdate,
  setOpen,
}) {
  const [isNext, setIsNext] = useState(null);
  const dispatch = useDispatch();
  const handleUpdateReference = (values) => {
    const finalData = { ...formData, ...values };
    setFormData((prev) => ({ ...prev, ...values }));
    isNext ? handleEditProfileData(finalData) : setCurrent((prev) => prev - 1);
  };

  const handleEditProfileData = (finalData) => {
    dispatch(
      allActions(finalData, {
        method: "post",
        endPoint: "profile_new",
        attempt: "UPDATE_PROFILE_DETAIL_REQUEST_ATTEMPT",
        success: "UPDATE_PROFILE_DETAIL_REQUEST_SUCCESS",
        failure: "UPDATE_PROFILE_DETAIL_REQUEST_FAILURE",

        saveBearerToken: false,
        successInternalState: () => {
          setOpen(false);
          fetchProfileUpdate();
        },
        failureInternalState: () => {},
      })
    );
  };
  return (
    <Form
      layout="vertical"
      initialValues={{ references: formData?.references }}
      onFinish={handleUpdateReference}
    >
      <Form.List name="references">
        {(fields, { add, remove }) => (
          <div
            style={{
              display: "flex",
              rowGap: 16,
              flexDirection: "column",
            }}
          >
            {fields.map((field) => (
              <Card
                size="small"
                title={`Reference ${field.name + 1}`}
                key={field.key}
                extra={
                  <CloseOutlined
                    style={{ color: "red" }}
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              >
                <div
                  style={{
                    padding: "0 1rem",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    gap: "0.5rem",
                  }}
                >
                  <Form.Item label="Contact Name" name={[field.name, "name"]}>
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Contact Name"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Company Name"
                    name={[field.name, "company"]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Company Name"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Designation"
                    name={[field.name, "designation"]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Designation"
                    />
                  </Form.Item>
                  <Form.Item label="Phone Number" name={[field.name, "phone"]}>
                    <InputNumber
                      type="number"
                      style={{ width: "100%" }}
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                  <Form.Item label="Email" name={[field.name, "email"]}>
                    <Input type="email" placeholder="Email" />
                  </Form.Item>
                </div>
              </Card>
            ))}

            <Button
              style={{ marginBottom: "1rem" }}
              type="dashed"
              onClick={() => add()}
              block
            >
              + Add Reference
            </Button>
          </div>
        )}
      </Form.List>

      <Button.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => setIsNext(false)}
          type="primary"
          htmlType="submit"
        >
          Back
        </Button>
        <Button
          onClick={() => setIsNext(true)}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Button.Group>
    </Form>
  );
}
