import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ImAttachment } from "react-icons/im";
import { useDispatch, connect } from "react-redux";
import { Button, Divider, Input, Spin, Tag, message } from "antd";
import ScrollToBottom from "react-scroll-to-bottom";
import {
  SendOutlined,
  UploadOutlined,
  WarningOutlined,
  LoadingOutlined,
  UserOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../../component/loader/MainLoader";
import { GrAttachment } from "react-icons/gr";
import { useRef } from "react";
import moment from "moment";
import {
  containsImageExtension,
  downloadImage,
} from "../../Helpers/FrontendHelper";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const InquiryDetail = ({ AgentAgencyReducer }) => {
  const {
    loading,
    inquiryListData,
    agentInquiryDetail,
    inquirystatusloading,
    inquirychatloading,
  } = AgentAgencyReducer;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const { inquiryId } = param;
  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    { title: "Inquiry List", link: "/find-agent-inquiry/inquiry-list" },
    {
      title: "Enquiry Details",
      link: `/enquiry-detail/${inquiryId}`,
    },
  ];

  const [inquiryText, setInquiryText] = useState("");
  const [inquiryDetail, setInquiryDetail] = useState(
    JSON.parse(localStorage.getItem("inquiryData"))
  );
  const [chatData, setChatData] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [file, setFile] = useState("");
  const [leadCat, setLeadCat] = useState([]);

  const fileRef = useRef();

  useEffect(() => {
    const tempData = JSON.parse(localStorage.getItem("inquiryData"));
    setInquiryDetail(tempData);
  }, []);

  useEffect(() => {
    if (agentInquiryDetail?.chats?.length > 0) {
      setChatData([...agentInquiryDetail?.chats].reverse());
    }
  }, [agentInquiryDetail]);

  const handleInquiryInputChange = (e) => {
    setInquiryText(e.target.value);
  };

  useEffect(() => {
    fetchInquiryTypeList();

    fetchAgentInquiryDetails();
  }, []);

  const fetchInquiryTypeList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "lead_enquiry_settings",
          attempt: "FETCH_INQUIRY_SETTING_REQUEST_ATTEMPT",
          success: "FETCH_INQUIRY_SETTING_REQUEST_SUCCESS",
          failure: "FETCH_INQUIRY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setLeadCat(res?.lead_categories);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const fetchAgentInquiryDetails = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `find_lead_agent_enquiry_detail/${inquiryDetail?.id}`,
          attempt: "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_ATTEMPT",
          success: "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_SUCCESS",
          failure: "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleUpdateInquiryStatus = (data, { status }) => {
    dispatch(
      allActions(
        {
          is_approved: status,
          enquiry_id: data?.id,
          app_name: "CLASSILEAD",
        },
        {
          method: "post",
          endPoint: `action_lead_enquiry`,
          attempt: "UPDATE_INQUIRY_STATUS_REQUEST_ATTEMPT",
          success: "UPDATE_INQUIRY_STATUS_REQUEST_SUCCESS",
          failure: "UPDATE_INQUIRY_STATUS_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            if (status === 1) {
              const tempData = { ...data, status: "Approved" };
              localStorage.setItem("inquiryData", JSON.stringify(tempData));
              setInquiryDetail({ ...data, status: "Approved" });
            } else if (status === 0) {
              localStorage.removeItem("inquiryData");
              setTimeout(() => {
                navigate("/find-agent-inquiry/inquiry-list");
              }, 1000);
            }
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleSendInquiry = () => {
    if (isFileUploaded) {
      const form = new FormData();
      form.append("files", file);

      const onlyWhitespace = /^\s*$/.test(file);
      onlyWhitespace
        ? message.warning("Please Upload the file")
        : dispatch(
            allActions(form, {
              method: "post",
              endPoint: "upload_comment_file",
              attempt: "POST_OFFER_FILE_REQUEST_ATTEMPT",
              success: "POST_OFFER_FILE_REQUEST_SUCCESS",
              failure: "POST_OFFER_FILE_REQUEST_FAILURE",
              multipartFormData: true,
              saveBearerToken: false,
              successInternalState: (res) => {
                handleSendMessage({ fileName: res?.source?.name });
              },
              failureInternalState: () => {
                setFile("");
                setIsFileUploaded(false);
              },
            })
          );
    } else {
      const onlyWhitespace = /^\s*$/.test(inquiryText);
      !onlyWhitespace && handleSendMessage({ fileName: "" });
    }
  };

  const handleSendMessage = ({ fileName }) => {
    dispatch(
      allActions(
        {
          chatfile: fileName,
          txt_reply: isFileUploaded ? file?.name : inquiryText,
          user_enquiry_id: inquiryDetail?.i_userid,
          app_name: "CLASSILEAD",
        },
        {
          method: "post",
          endPoint: `add_lead_enquiryChatMsg`,
          attempt: "SEND_INQUIRY_REQUEST_ATTEMPT",
          success: "SEND_INQUIRY_REQUEST_SUCCESS",
          failure: "SEND_INQUIRY_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            setInquiryText("");
            fetchAgentInquiryDetails();
            setIsFileUploaded(false);
            setFile("");
          },
          failureInternalState: () => {
            setInquiryText("");
            fetchAgentInquiryDetails();
            setIsFileUploaded(false);
            setFile("");
          },
        }
      )
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendInquiry();
    }
  };

  const handleChangeFileUpload = (e) => {
    setFile(e.target.files[0]);
    setIsFileUploaded((prev) => true);
  };

  return (
    <DashboardLayout>
      {inquirystatusloading && <MainLoader />}
      <Breadcrumb title="Enquiry Detail" crumbList={crumbList} />
      <div className={"enquiry--detail--container"}>
        <h3>Details</h3>
        <Divider style={{ margin: "0" }} />
        <div className="inquiry--detail--container">
          <div className="inner--left--child--enquirycontainer">
            <div className="two--row--container border--padding-style">
              <div className="two--row--child--container">
                <strong> Name: </strong>
                <p>
                  {inquiryDetail?.status.toLowerCase() === "pending" ||
                  inquiryDetail?.status.toLowerCase() === "declined"
                    ? "xxxxx"
                    : inquiryDetail?.name}
                </p>
              </div>
              <div className="two--row--child--container">
                <strong> Email: </strong>
                <p>
                  {inquiryDetail?.status.toLowerCase() === "pending" ||
                  inquiryDetail?.status.toLowerCase() === "declined"
                    ? "xxxxx"
                    : inquiryDetail?.email}
                </p>
              </div>
            </div>
            <div className="two--row--container border--padding-style">
              <div className="two--row--child--container">
                <strong> Phone: </strong>
                <p>
                  {inquiryDetail?.status.toLowerCase() === "pending" ||
                  inquiryDetail?.status.toLowerCase() === "declined"
                    ? "xxxxx"
                    : inquiryDetail?.name}
                </p>
              </div>
              <div className="two--row--child--container">
                <strong> Enquiry For:</strong>{" "}
                {/* <p>{inquiryDetail?.enquiry_for}</p> */}
                <p>
                  {
                    leadCat?.find(
                      (data) => data?.cat_id === inquiryDetail?.lead_cat_id
                    )?.cat_name
                  }
                </p>
              </div>
            </div>
            <div className="border--padding-style">
              <strong> Timeframe: </strong>
              <p>{inquiryDetail?.when_start}</p>
            </div>

            <div className="border--padding-style">
              <strong>Location:</strong>
              <p>{inquiryDetail?.address}</p>
            </div>
            <div className="border--padding-style">
              <strong>Detail:</strong>
              <p style={{ height: "6.5rem" }}>{inquiryDetail?.description}</p>
            </div>
            <div className="two--row--container border--padding-style">
              <div className="two--row--child--container">
                <strong> Status: </strong>
                {inquiryDetail?.status.toLowerCase() === "pending" ? (
                  <Button
                    size="small"
                    style={{
                      background: "#62CDFF",
                      borderColor: "#62CDFF",
                      color: "white",
                      marginLeft: "1rem",
                    }}
                    primary
                  >
                    {inquiryDetail?.status}
                  </Button>
                ) : (
                  <Button
                    size="small"
                    style={{
                      background:
                        inquiryDetail?.status.toLowerCase() === "declined"
                          ? "red"
                          : "#03C988",
                      borderColor:
                        inquiryDetail?.status.toLowerCase() === "declined"
                          ? "red"
                          : "#03C988",

                      color: "white",
                    }}
                  >
                    {inquiryDetail?.status}
                  </Button>
                )}
              </div>
              {inquiryDetail?.status.toLowerCase() === "pending" && (
                // ||
                //   inquiryDetail?.status.toLowerCase() === "declined")
                <div
                  className="two--row--child--container"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    paddingLeft: "1rem",
                    borderLeft: "1px solid #d1c7c7",
                  }}
                >
                  <Button
                    size="small"
                    style={{
                      background: "#03C988",
                      borderColor: "#03C988",
                      color: "white",
                    }}
                    onClick={() => {
                      handleUpdateInquiryStatus(inquiryDetail, { status: 1 });
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    size="small"
                    style={{
                      background: "#F24C3D",
                      borderColor: "#F24C3D",
                      color: "white",
                    }}
                    onClick={() => {
                      handleUpdateInquiryStatus(inquiryDetail, { status: 0 });
                    }}
                  >
                    Decline
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="inner--right--child--enquirycontainer">
            <div className="inquiry--chat--header">
              {inquiryDetail?.status.toLowerCase() === "pending" ||
              inquiryDetail?.status.toLowerCase() === "declined" ? (
                <Tag
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                  color="#3C84AB"
                >
                  <WarningOutlined style={{ color: "white" }} /> Enquiry should
                  be approved to start chat with customer.
                </Tag>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    padding: "0.5rem 1rem",
                    borderRadius: "0",
                    background: "white",
                    border: "1px solid #eeeeee",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <UserOutlined
                      style={{
                        color: "gray",
                        background: "white",
                        fontSize: "1.5rem",
                        height: "fit-content",
                        padding: "0.2rem",
                        borderRadius: "100%",
                        border: "1px solid gray",
                      }}
                    />
                    <span color="gray"> {inquiryDetail?.name}</span>
                  </div>
                  <SyncOutlined
                    style={{
                      fontSize: "1.3rem",
                      color: "gray",
                      cursor: "pointer",
                    }}
                    onClick={() => fetchAgentInquiryDetails()}
                  />
                </div>
              )}
            </div>

            <ScrollToBottom
              style={{
                overflowY: "scroll",
                backgroundColor: "white",
                height: "22rem",
              }}
            >
              <div style={{ height: "22rem" }}>
                <div className="inquiry--chat--body">
                  {inquiryDetail?.status?.toLowerCase() === "pending" ||
                  inquiryDetail?.status?.toLowerCase() === "declined" ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <WarningOutlined
                        style={{
                          fontSize: "4rem",
                          color: "white",
                          padding: "1rem",
                          borderRadius: "100%",
                          background: "#F16767",
                        }}
                      />
                      <span style={{ fontSize: "1rem", color: "#F16767" }}>
                        Inquiry is {inquiryDetail?.status}, Approve First!
                      </span>
                    </div>
                  ) : (
                    <>
                      {chatData.length > 0 &&
                        chatData?.map((item) => {
                          return (
                            <div
                              className={
                                inquiryDetail?.user_id === item?.user_id
                                  ? "sender"
                                  : "receiver"
                              }
                            >
                              <div
                                className={
                                  inquiryDetail?.user_id === item?.user_id
                                    ? "sender--inner--div"
                                    : "receiver--inner--div"
                                }
                              >
                                {item?.file_url ? (
                                  containsImageExtension(item?.file_url) ? (
                                    <div>
                                      <img
                                        src={item?.file_url}
                                        style={{
                                          width: "12rem",
                                          height: "auto",
                                          maxHeight: "9rem",
                                          objectFit: "cover",
                                        }}
                                        alt=""
                                      />

                                      <span
                                        onClick={() =>
                                          downloadImage(
                                            item?.file_url,
                                            item?.msg
                                          )
                                        }
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "0.3rem",
                                          padding: "0.3rem 0.4rem",
                                          fontSize: "12px",
                                          borderRadius: "0.5rem",
                                          cursor: "pointer",
                                          alignItems: "center",
                                        }}
                                      >
                                        {" "}
                                        <GrAttachment /> download
                                      </span>
                                    </div>
                                  ) : (
                                    <a
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.3rem",
                                        border: "1px solid #78a2ff",
                                        padding: "0.3rem 0.4rem",
                                        fontSize: "12px",
                                        borderRadius: "0.5rem",
                                        cursor: "pointer",
                                        alignItems: "center",
                                      }}
                                      href={item?.file_url}
                                    >
                                      <GrAttachment />{" "}
                                      {item?.msg ? item?.msg : "Attachment..."}
                                    </a>
                                  )
                                ) : (
                                  <p
                                    className={
                                      inquiryDetail?.user_id === item?.user_id
                                        ? "sender--message"
                                        : "receiver--message"
                                    }
                                  >
                                    {item?.msg}
                                  </p>
                                )}

                                <p>
                                  {" "}
                                  <span style={{ fontSize: "0.7rem" }}>
                                    {item?.first_name}
                                  </span>
                                  ,
                                  <span style={{ fontSize: "0.7rem" }}>
                                    {moment.utc(item?.gmt).local().fromNow()}
                                  </span>
                                </p>
                              </div>
                              <img
                                style={{
                                  width: "2.5rem",
                                  height: "2.5rem",
                                  objectFit: "cover",
                                  borderRadius: "100%",
                                  border: "1px solid #CEE5D0",
                                }}
                                src={item?.thumbnail}
                                alt=""
                              />
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
              </div>
            </ScrollToBottom>

            <div style={{ flex: 1 }} className="inquiry--chat--footer">
              <input
                ref={fileRef}
                onChange={handleChangeFileUpload}
                // accept=".pdf,.doc,.docx"
                type="file"
                style={{ display: "none" }}
              />
              {isFileUploaded ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    textAlign: "center",
                    background: "white",
                    padding: "0.5rem",
                  }}
                >
                  {file?.name}{" "}
                  <CloseCircleOutlined
                    onClick={() => {
                      setIsFileUploaded((prev) => false);
                      setFile("");
                    }}
                    style={{
                      fontSize: "1rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <ImAttachment
                  className="inquiry--file--upload"
                  onClick={() => fileRef.current.click()}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                  border: isFileUploaded ? "none" : "1px solid #DFD7BF",
                  width: isFileUploaded ? "fit-content" : "100%",
                  paddingRight: "0.5rem",
                  borderRadius: "3px",
                }}
              >
                {!isFileUploaded && (
                  <Input
                    style={{
                      padding: "0.5rem",
                      outline: "none",
                      border: "none",
                    }}
                    value={inquiryText}
                    onKeyDown={handleKeyPress}
                    onChange={handleInquiryInputChange}
                    placeholder="Type here..."
                  />
                )}
                {inquirychatloading ? (
                  <LoadingOutlined className="inquiry--send--button" />
                ) : (
                  <SendOutlined
                    onClick={handleSendInquiry}
                    className="inquiry--send--button"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InquiryDetail);
